import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "month", "year" ]


  changeMonth() {
    this._navigateToNewLocation()
  }


  changeYear() {
    this._navigateToNewLocation()
  }


  _navigateToNewLocation() {
    window.location.href = new DestinationHrefBuilder(this.monthTarget.value, this.yearTarget.value).build()
  }
}


class DestinationHrefBuilder {
  constructor(month, year) {
    this.month = month
    this.year = year
  }


  build() {
    return location.origin + location.pathname + this._buildSearchParams()
  }


  _buildSearchParams() {
    const otherSearchPairs = this._extractOtherSearchPairs()
    const startDateSearchPair = this._buildStartDatePair()
    // TODO: There might be a bug here with the concat.  This might need to be push
    const searchPairs = otherSearchPairs.concat(startDateSearchPair)

    if (searchPairs.length > 0) {
      const pairToParamString = ([param, value]) => { return param + "=" + value }
      return "?" + searchPairs.map(pairToParamString).join("&")
    }
    else {
      return ""
    }
  }


  _extractOtherSearchPairs() {
    // Create a URLSearchParams because it looks like the global
    // location object doesn't always have a populated searchParams
    // field.
    const oldSearchParams = new URLSearchParams(location.search)
    const oldSearchParamsEntries = Array.from(oldSearchParams.entries())
    const isNotStartDateEntry = ([key, _value]) => { return key !== "start_date" }
    return oldSearchParamsEntries.filter(isNotStartDateEntry)
  }


  _buildStartDatePair() {
    const pair = ["start_date", [ this.year, this.month, "01" ].join("-") ]
    return [ pair ]
  }
}
