import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "all", "reservable" ]


  connect() {
    // We're listening for a jQuery event, not a Stimulus one.
    $(this.element).on('hide.bs.dropdown', (_event) => {
      // TODO: Only do this if the filter settings have changed
      this._navigateToNewLocation()
    })
  }


  changeAllReservables() {
    if (this.allTarget.checked) {
      this._checkAllReservables()
    }
    else {
      this._uncheckAllReservables()
    }
  }


  changeOneReservable(event) {
    if (event.target.checked) {
      this._maybeCheckAllCheckbox()
    }
    else {
      this._uncheckAllCheckbox()
    }
  }


  _checkAllReservables() {
    this.reservableTargets.forEach((reservable) => { reservable.checked = true })
  }


  _uncheckAllReservables() {
    this.reservableTargets.forEach((reservable) => { reservable.checked = false })
  }


  _maybeCheckAllCheckbox() {
    if (this._everyReservableChecked()) {
      this.allTarget.checked = true
    }
  }


  _uncheckAllCheckbox() {
    this.allTarget.checked = false
  }


  _everyReservableChecked() {
    return this.reservableTargets.every((reservable) => { return reservable.checked })
  }


  _navigateToNewLocation() {
    window.location.href = new DestinationHrefBuilder(this.reservableTargets).build()
  }
}


class DestinationHrefBuilder {
  constructor(reservableTargets) {
    this.reservableTargets = reservableTargets
  }


  build() {
    return location.origin + location.pathname + this._buildSearchParams()
  }


  _buildSearchParams() {
    const otherSearchPairs = this._extractOtherSearchPairs()
    const reservableSearchPairs = this._buildReservablePairs()
    // TODO: There might be a bug here with the concat.  This might need to be push
    const searchPairs = otherSearchPairs.concat(reservableSearchPairs)

    if (searchPairs.length > 0) {
      const pairToParamString = ([param, value]) => { return param + "=" + value }
      return "?" + searchPairs.map(pairToParamString).join("&")
    }
    else {
      return ""
    }
  }


  _extractOtherSearchPairs() {
    // Create a URLSearchParams because it looks like the global
    // location object doesn't always have a populated searchParams
    // field.
    const oldSearchParams = new URLSearchParams(location.search)
    const oldSearchParamsEntries = Array.from(oldSearchParams.entries())
    const isNotAReservableEntry = ([key, _value]) => { return key !== "reservable_ids[]" }
    return oldSearchParamsEntries.filter(isNotAReservableEntry)
  }


  _buildReservablePairs() {
    const isReservableChecked = (reservable) => { return reservable.checked }
    const buildPairs = (reservable) => { return ["reservable_ids[]", reservable.dataset.id] }
    const pairs = this.reservableTargets.filter(isReservableChecked).map(buildPairs)
    return pairs.length !== this.reservableTargets.length ? pairs : []
  }
}
