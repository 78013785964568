import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "reservable" ]

  fetchPageForReservable() {
    window.location.href = new DestinationHrefBuilder(this.reservableTarget).build()
  }
}


class DestinationHrefBuilder {
  constructor(reservableTarget) {
    this.reservableTarget = reservableTarget
  }


  build() {
    return location.origin + location.pathname + this._buildSearchParams()
  }


  _buildSearchParams() {
    const searchPairs = []

    const otherSearchPairs = this._extractOtherSearchPairs()
    if (otherSearchPairs.length > 1) {
      searchPairs.push(otherSearchPairs)
    }

    const reservableSearchPair = this._buildReservablePair()
    if (reservableSearchPair.length > 1) {
      searchPairs.push(reservableSearchPair)
    }

    if (searchPairs.length > 0) {
      const pairToParamString = ([param, value]) => { return param + "=" + value }
      return "?" + searchPairs.map(pairToParamString).join("&")
    }
    else {
      return ""
    }
  }


  _extractOtherSearchPairs() {
    // Create a URLSearchParams because it looks like the global
    // location object doesn't always have a populated searchParams
    // field.
    const oldSearchParams = new URLSearchParams(location.search)
    const oldSearchParamsEntries = Array.from(oldSearchParams.entries())
    const isNotAReservableEntry = ([key, _value]) => { return key !== "reservable_id" }
    return oldSearchParamsEntries.filter(isNotAReservableEntry)
  }


  _buildReservablePair() {
    console.log("DA VALUE", this.reservableTarget.value)
    return ["reservable_id", this.reservableTarget.value]
  }
}