import Flatpickr from "stimulus-flatpickr";


export default class extends Flatpickr {
  connect() {
    const controller = this;

    this.config = {
      disable: [
        function(dateToCheck) {
          return controller.isDateDisabled(dateToCheck)
        }
      ],
      locale: {
        firstDayOfWeek: this.firstDayOfWeek
      }
    }

    super.connect();
  }


  isDateDisabled(dateToCheck) {
    return !this.isWaitlisting && this.isBooked(dateToCheck)
  }


  isBooked(dateToCheck) {
    return this.bookedOnDates.some(
      function(bookedRange) {
        return (dateToCheck.getTime() >= bookedRange.from.getTime()) &&
          (dateToCheck.getTime() <= bookedRange.to.getTime());
      })
  }


  get firstDayOfWeek() {
    return this.data.get("firstDayOfWeek");
  }


  get isWaitlisting() {
    return this.data.get("waitlisting") === "true";
  }


  get arriveOn() {
    return this.data.get("arriveOn");
  }


  set arriveOn(dateStr) {
    this.data.set("arriveOn", dateStr);
  }


  get departOn() {
    return this.data.get("departOn");
  }


  set departOn(dateStr) {
    this.data.set("departOn", dateStr);
  }


  get arriveOnDate() {
    return flatpickr.parseDate(this.data.get("arriveOn"));
  }


  get departOnDate() {
    return flatpickr.parseDate(this.data.get("departOn"));
  }


  get bookedOnDates() {
    if (this._bookedOnDates === undefined) {
      this._bookedOnDates = this._parseBookedOnDates()
    }
    return this._bookedOnDates;
  }


  _parseBookedOnDates() {
    return JSON.parse(this.data.get("bookedOnDates")).map(
      function(dateRange) {
        return {
          to: flatpickr.parseDate(dateRange.to),
          from: flatpickr.parseDate(dateRange.from)
        }
      }
    );
  }
}
