import BookingDateController from "./booking_date_controller";


export default class extends BookingDateController {
  dayCreate(selectedDates, dateStr, instance, dayElem) {
    const arriveOnDate = this.arriveOnDate.getTime();
    const departOnDate = this.departOnDate.getTime();
    const currentDate = dayElem.dateObj.getTime();

    if ((arriveOnDate < currentDate) && (currentDate < departOnDate)) {
      dayElem.classList.add("inRange");
    }
    else {
      if (currentDate === arriveOnDate) {
        dayElem.classList.add("startRange");
      }
      if (currentDate === departOnDate) {
        dayElem.classList.add("endRange");
      }
    }

    if (this.isWaitlisting && this.isBooked(dayElem.dateObj)) {
      dayElem.classList.add("flatpickr-reserved");
    }
  }


  change(selectedDates, dateStr, instance) {
    // This is a workaround for the case where the the text in the
    // input has been deleted.  In that case, we restore the last
    // value we had.
    //
    // Because we need to do this, the calendar was probably updated
    // with the wrong departOn.  Now that we've set it correctly, we
    // trigger a redraw of the calendar.
    if (dateStr !== "") {
      this.departOn = dateStr;
    } else {
      this.fp.input.value = this.departOn;
    }
    this.fp.redraw();

    // Tell the arriveOn field that this field has changed its value.
    this.arriveOnController.departOnChanged(this.departOn);
  }


  arriveOnChanged(arriveOn) {
    this.arriveOn = arriveOn;
    this.fp.set("minDate", arriveOn) // Triggers a re-draw
  }


  get arriveOnController() {
    const element = document.querySelector("#night_reservation_arrive_on");
    return this.application.getControllerForElementAndIdentifier(element, "flatpickr-arrive-on");
  }
}
